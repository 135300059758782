import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants";
import Form from "react-bootstrap/Form";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import Texterror from "../Texterror";
import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Ico1 from "../../assets/img/edit.png";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  TargetValidation
} from "../../utils/validator";

function DetailModal(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [entries, setEntries] = useState([]);
  const [targetYearData, setTargetYearData] = useState([]);
  const [payperiodList, setPayperiodList] = useState([]);
  const [payperiodValue, setPayperiodValue] = useState({});
  const [targetList, setTargetList] = useState([]);
  const [formData, setFormData] = useState({ date: "", target: "" });
  const [editIndex, setEditIndex] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialData, setISInitialData] = useState(false);

   const [initialValues, setInitialValues] = useState({
     target: "",
     payperiod_id: "",
     payperiod_year: ""
   });
   
    const formatDateToDDMMYYYY = (dateString) => {
      return moment(dateString, "DD-MM-YYYY")?.format("MM/DD/YYYY");
    };



      const targetYearList = async () => {
        setIsLoading(true);
        try {
          const { data } = await axios.post(
            `${API_CALL_URL_PRIVATE}/general/ppPhysicalYearList`,
            {
              customer_code: customer_code,
            }
          );

          console.log("pp", data);
          

          setTargetYearData(
            data.data?.map((val) => {
              return {
                label: val,
                value: val,
              };
            })
          );
          setPayperiodValue({
            label: data?.data[0],
            value: data?.data[0],
          });
          
          
          if(data?.data[0]) {
            targetPeriod(data?.data[0]);
          }

        } catch (error) {
          console.error("Failed to fetch user data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      

  const targetPeriod = async (year) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/payperiodList`,
        {
          customer_code: customer_code,
          fiscal_year: year,
        }
      );

       setPayperiodList(
         data.data?.map((val) => {
           return {
             label: formatDateToDDMMYYYY(val.end_date),
             value: val._id,
           };
         })
       );
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  

  useEffect(() => {
    if (props.show) {
      targetYearList();
      // fetchUserData();
      getTargetHistoryList();
    }
  }, [props.show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Add or Update Entry
  const handleSubmit = (e) => {
    e.preventDefault();
    if (editIndex !== null) {
      // Update Entry
      const updatedEntries = [...entries];
      updatedEntries[editIndex] = formData;
      setEntries(updatedEntries);
      setEditIndex(null);
    } else {
      // Add New Entry
      setEntries([...entries, formData]);
    }
    setFormData({ date: "", target: "" });
  };

  // Edit Entry
  const handleEdit = (index) => {
    setFormData(entries[index]);
    setEditIndex(index);
  };

  // Delete Entry
  const handleDelete = (index) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
  };

  function formatDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  function MyComponent({ htmlContent }) {
    console.log("htmlContent", htmlContent);
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  }
  const handleTest = () => {};



    const onSubmit = async (values) => {
      console.log("values", values);
      
         const obj = {
           cost_center_number: props?.costCenterNumber,
           target: values?.target,
           payperiod_id: values?.payperiod_id,
         };
    try {
      // setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/costcentertarget/create`,
        obj
      );

      getTargetHistoryList();
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // navigate("/admin/facility");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
    };

    const getTargetHistoryList = async () => {
      try {
        const resp = await axios.get(
          `${API_CALL_URL_PRIVATE}/costcentertarget/list/${props?.costCenterNumber}?customer_code=${encodeURIComponent(
            customer_code
          )}&sort_column=${encodeURIComponent(
            searchActive ? "" : sortKey
          )}&sort_order=${encodeURIComponent(
            searchActive ? -1 : sortOrder
          )}&limit=${encodeURIComponent(pageLimit)}&page=${encodeURIComponent(
            searchActive ? 1 : currentPage
          )}`
        );

        const Data = resp?.data?.data?.data;
        setTargetList(Data);
        setISInitialData(true);
        // setSearchActive(false);
        // setCustomerData(Data);
        // setPageLimit(Data.limit ? Data.limit : 50);
        // setTotalPages(Data?.totalPages);
        // setCurrentPage(Data.page ? Data.page : 1);
        // setHasNext(Data?.hasNextPage);
        // setHasPrev(Data?.hasPrevPage);
        // setPageCounter(Data?.pagingCounter);
        // setListLoader(false);
        // setISInitialData(true);
        // dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };



    
    
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="custom-font-size">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fw-normal color-black f-18 text-center"
        >
          {editIndex ? "Update Target" : "Add Target"}
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body className="custom-font-size text-indent-15"> */}
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: "230px" }}
        >
          <span
            class="loader"
            style={{
              width: "50px",
              height: "50px",
            }}
          ></span>
        </div>
      ) : (
        // <div class="card-body">
        //   <div className="row align-items-center">
        //     {/* <div className="col-3">
        //   </div> */}
        //     <div className="col-12">
        //       <h4 class="card-title text-center">Update Settings</h4>
        //     </div>
        //   </div>
        //   <div class="row">
        //     <Form.Group
        //       className="col-md-4 mb-3"
        //       controlId="exampleForm.c_code"
        //     >
        //       <Form.Label>Title</Form.Label>
        //       <Form.Control
        //         type="text"
        //         placeholder=""
        //         name="key"
        //         // value={formik.values.key}
        //         // {...formik.getFieldProps("key")}
        //       />
        //       {/* <ErrorMessage name="key" component={Texterror} /> */}
        //     </Form.Group>

        //   </div>
        // </div>
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={TargetValidation}
            enableReinitialize
            validateOnChange
          >
            {(formik) => (
              <div className="main-panel">
                {console.log(formik)}
                <div className="content-wrapper">
                  <div className="page-header">
                    <h3 className="page-title">
                      <a href="#" className="badge badge-dark"></a>
                    </h3>
                    <div className></div>
                  </div>
                  <div className="row">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card border-0">
                        <FormikForm className="forms-sample">
                          <div class="card-body">
                            <div class="row">
                              <Form.Group
                                className="col-md-4 mb-4"
                                controlId="exampleForm.c_code"
                              >
                                <Form.Label>Target</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="target"
                                  value={formik.values.target}
                                  {...formik.getFieldProps("target")}
                                />
                                <ErrorMessage
                                  name="target"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-4 mb-3">
                                <Form.Label>Year</Form.Label>
                                <Select
                                  className="select-style"
                                  value={payperiodValue}
                                  // value={
                                  //   dropDateData.label
                                  //     ? dropDateData
                                  //     : dateData[0]
                                  // }
                                  options={targetYearData}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "payperiod_year",
                                      e.value
                                    );
                                    targetPeriod(e.value);
                                    setPayperiodValue(e);
                                  }}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-4 mb-3">
                                <Form.Label>Pay Period End Date</Form.Label>
                                <Select
                                  className="select-style"
                                  // value={
                                  //   dropDateData.label
                                  //     ? dropDateData
                                  //     : dateData[0]
                                  // }
                                  options={payperiodList}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "payperiod_id",
                                      e.value
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="payperiod_id"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-4 d-flex align-items-center mt-2 ">
                                <Button
                                  // className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {editIndex ? "Update Target" : "Add Target"}
                                </Button>

                                {editIndex && (
                                  <Button
                                    className="ms-3"
                                    variant="danger"
                                    type="submit"
                                    disabled={isLoading}
                                    onClick={() => {
                                      setEditIndex(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </Form.Group>
                            </div>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>

          <div>
            <div className="table-responsive m-4">
              <table className="table filter-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Target</th>
                    {/* <th>Start Date</th> */}
                    <th>Pay Period End Date</th>
                    <th></th>
                  </tr>
                </thead>
                {targetList?.docs?.length > 0 ? (
                  <tbody>
                    {targetList?.docs?.map((Ele, i) => {
                      return (
                        <tr key={Ele?._id}>
                          <td>{i + 1}</td>
                          <td>{Ele?.target}</td>
                          {/* <td>{formatDateToDDMMYYYY(Ele?.start_date)}</td> */}
                          <td>{formatDateToDDMMYYYY(Ele?.end_date)}</td>
                          <td>
                            <label className="edit">
                              <div
                                className="boxlink"
                                onClick={() => {
                                  setEditIndex(true);
                                }}
                                // to={`/admin/settings/edit-settings/${Ele?._id}`}
                              >
                                <img src={Ico1} className="w-a" alt="icon" />
                              </div>
                            </label>
                            {/* {" "}
                               &nbsp;
                               <label className="edit">
                                 <img src={Ico2} className="w-a" />
                               </label> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="10">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <p class="text-secondary display-6">
                            Oops! Data Not found
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </>
        // <div className="row">
        //   <div class="col-12 grid-margin stretch-card">
        //     <div class="card">
        //       <FormikForm className="forms-sample">
        //         <div class="card-body">
        //           <div className="row align-items-center">
        //             <div className="col-3">
        //               <Link
        //                 to={`/admin/settings`}
        //                 className="f-14 white-text-dark-mode"
        //               >
        //                 <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
        //                 Back
        //               </Link>
        //             </div>
        //             <div className="col-6">
        //               <h4 class="card-title text-center">Update Settings</h4>
        //             </div>
        //           </div>

        //           <div class="row">
        //             <Form.Group
        //               className="col-md-4 mb-3"
        //               controlId="exampleForm.c_code"
        //             >
        //               <Form.Label>Title</Form.Label>
        //               <Form.Control
        //                 type="text"
        //                 placeholder=""
        //                 name="key"
        //                 value={formik.values.key}
        //                 {...formik.getFieldProps("key")}
        //               />
        //               <ErrorMessage name="key" component={Texterror} />
        //             </Form.Group>
        //             <Form.Group
        //               className="col-md-4 mb-3"
        //               controlId="exampleForm.c_email"
        //             >
        //               <Form.Label>Customer</Form.Label>
        //               <Form.Select
        //                 aria-label="Default select example"
        //                 className="white"
        //                 name="customer_code"
        //                 value={`${formik.values.customer_code}`}
        //                 onChange={(e) => {
        //                   formik.setFieldTouched("customer_code", true, true);
        //                   formik.setFieldValue(
        //                     "customer_code",
        //                     e.target.value
        //                   );
        //                 }}
        //                 // onChange={formik.handleChange}
        //               >
        //                 <option value={""}>Select</option>
        //                 {CustomerCodeOptions?.map((e) => {
        //                   return (
        //                     <option
        //                       key={e?.customer_code}
        //                       value={e?.customer_code}
        //                     >
        //                       {e?.customer_name}
        //                     </option>
        //                   );
        //                 })}
        //               </Form.Select>
        //               <ErrorMessage
        //                 name="customer_code"
        //                 component={Texterror}
        //               />
        //             </Form.Group>

        //             <Form.Group
        //               className="col-md-4 mb-3"
        //               controlId="exampleForm.c_email"
        //             >
        //               <Form.Label>Status</Form.Label>
        //               <Form.Select
        //                 aria-label="Default select example"
        //                 className="white"
        //                 name="status"
        //                 value={formik.values.status}
        //                 onChange={(e) => {
        //                   formik.setFieldTouched("status", true, true);
        //                   if (e.target.value !== "") {
        //                     formik.setFieldValue("status", e.target.value);
        //                   }
        //                   // formik.setFieldValue(
        //                   //   "customer_status",
        //                   //   e.target.value
        //                   // );
        //                   // console.log(e.target.value);
        //                 }}
        //                 onBlur={() => {
        //                   formik.setFieldTouched("status", true, true);
        //                 }}
        //                 // onChange={formik.handleChange}
        //               >
        //                 <option value="1">Active</option>
        //                 <option value="0">Inactive</option>
        //               </Form.Select>
        //               <ErrorMessage name="status" component={Texterror} />
        //             </Form.Group>
        //             <Form.Group className="col-md-3 mb-3">
        //               <Form.Label>Start Date</Form.Label>
        //               <DatePicker
        //                 selected={formik.values.start_date}
        //                 onChange={(date) =>
        //                   formik.setFieldValue("start_date", date)
        //                 }
        //                 dateFormat="MM/dd/yyyy"
        //                 className="form-control"
        //                 placeholderText="Select start date"
        //               />
        //               <ErrorMessage
        //                 name="start_date"
        //                 component="div"
        //                 className="text-danger"
        //               />
        //             </Form.Group>

        //             <Form.Group className="col-md-3 mb-3">
        //               <Form.Label>Start Time</Form.Label>
        //               <DatePicker
        //                 selected={formik.values.start_time}
        //                 onChange={(time) => {
        //                   // Convert to local time format
        //                   const localTime = moment(time).format(
        //                     "YYYY-MM-DDTHH:mm:ss"
        //                   );
        //                   formik.setFieldValue("start_time", time);
        //                 }}
        //                 showTimeSelect
        //                 showTimeSelectOnly
        //                 timeIntervals={15}
        //                 dateFormat="h:mm aa"
        //                 className="form-control"
        //                 placeholderText="Select start time"
        //               />
        //               <ErrorMessage
        //                 name="start_time"
        //                 component="div"
        //                 className="text-danger"
        //               />
        //             </Form.Group>

        //             {/* End Date Field */}
        //             <Form.Group className="col-md-3 mb-3">
        //               <Form.Label>End Date</Form.Label>
        //               <DatePicker
        //                 selected={formik.values.end_date}
        //                 onChange={(date) =>
        //                   formik.setFieldValue("end_date", date)
        //                 }
        //                 minDate={formik.values.start_date || new Date()}
        //                 dateFormat="MM/dd/yyyy"
        //                 className="form-control"
        //                 placeholderText="Select end date"
        //               />
        //               <ErrorMessage
        //                 name="end_date"
        //                 component="div"
        //                 className="text-danger"
        //               />
        //             </Form.Group>

        //             <Form.Group className="col-md-3 mb-3">
        //               <Form.Label>End Time</Form.Label>
        //               <DatePicker
        //                 selected={formik.values.end_time}
        //                 onChange={(time) => {
        //                   const localTime = moment(time)?.format(
        //                     "YYYY-MM-DDTHH:mm:ss"
        //                   );
        //                   formik.setFieldValue("end_time", time);
        //                 }}
        //                 showTimeSelect
        //                 showTimeSelectOnly
        //                 timeIntervals={15}
        //                 dateFormat="h:mm aa"
        //                 className="form-control"
        //                 placeholderText="Select end time"
        //               />
        //               <ErrorMessage
        //                 name="end_time"
        //                 component="div"
        //                 className="text-danger"
        //               />
        //             </Form.Group>
        //             <Form.Group
        //               className="col-md-12 mb-3"
        //               controlId="exampleForm.c_name"
        //             >
        //               <Form.Label>Message</Form.Label>
        //               <Form.Control
        //                 as="textarea"
        //                 rows={3}
        //                 placeholder=""
        //                 name="value"
        //                 {...formik.getFieldProps("value")}
        //               />
        //               <ErrorMessage name="value" component={Texterror} />
        //             </Form.Group>
        //             <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
        //               {/* <Button variant="primary" type="submit">
        //                     Reset
        //                   </Button> */}
        //               <Button
        //                 className="ms-3"
        //                 variant="success"
        //                 type="submit"
        //                 disabled={isLoading}
        //               >
        //                 {isLoading ? "Saving..." : "Save"}
        //               </Button>
        //             </Form.Group>
        //           </div>
        //         </div>
        //       </FormikForm>
        //     </div>
        //   </div>
        // </div>

        // <div>
        //   <h1>Add Date and Target</h1>
        //   <form onSubmit={handleSubmit}>
        //     <div>
        //       <label>Date:</label>
        //       <input
        //         type="date"
        //         name="date"
        //         value={formData.date}
        //         onChange={handleChange}
        //         required
        //       />
        //     </div>
        //     <div>
        //       <label>Target:</label>
        //       <input
        //         type="text"
        //         name="target"
        //         value={formData.target}
        //         onChange={handleChange}
        //         required
        //       />
        //     </div>
        //     <button type="submit">
        //       {editIndex !== null ? "Update" : "Add"}
        //     </button>
        //     {editIndex !== null && (
        //       <button
        //         type="button"
        //         onClick={() => {
        //           setFormData({ date: "", target: "" });
        //           setEditIndex(null);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //     )}
        //   </form>

        //   <h2>View Entries</h2>
        //   <table>
        //     <thead>
        //       <tr>
        //         <th>Date</th>
        //         <th>Target</th>
        //         <th>Actions</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {entries.map((entry, index) => (
        //         <tr key={index}>
        //           <td>{entry.date}</td>
        //           <td>{entry.target}</td>
        //           <td>
        //             <button onClick={() => handleEdit(index)}>Edit</button>
        //             <button onClick={() => handleDelete(index)}>
        //               Delete
        //             </button>
        //           </td>
        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        // </div>
      )}
      {/* </Modal.Body> */}
    </Modal>
  );
}

export default DetailModal;
