import React, { useContext, useEffect, useState, useRef } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { departmentValidation } from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPage } from "../../store/actions";
import Select from "react-select";
import Ico1 from "../../assets/img/edit.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import TextEditor from "../TextEditor/TextEditor";
import Accordion from "react-bootstrap/Accordion";
import calcIcon from "../../assets/img/calculator_hires.png";
import dragDark from "../../assets/img/drop-black.png";
import dragWhite from "../../assets/img/drop-white.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import TargetHistoryModal from "../../components/TargetHistoryModal";


function EditNewCostCenter() {
  const { ID, costCenter } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [hireDate, setHireDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [terminateDate, setTerminateDate] = useState(null);
  const [isRealtime, setIsRealtime] = useState(false);
  const [formControl, setFormControl] = useState([]);
  const [realtimeFormula, setRealtimeFormula] = useState(null);
  const [realtimeFormulaHeaders, setRealtimeFormulaHeaders] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [targettypeList, setTargetTypeList] = useState([]);
  const [formDetailData, setFormDetailData] = useState([]);
  const [showAllPatient, setShowAllPatient] = useState(false);
  const [showAllStaffing, setShowAllStaffing] = useState(false);
  const [showAllAdditional, setShowAllAdditional] = useState(false);
  const [sortedPatientData, setSortedPatientData] = useState([]);
  const [visiblePatientRows, setVisiblePatientRows] = useState([]);
  const [hiddenPatientRows, setHiddenPatientRows] = useState([]);
  const [allZeroPatient, setAllZeroPatient] = useState(false);
  const [visibleStaffingRows, setVisibleStaffingRows] = useState([]);
  const [hiddenStaffingRows, setHiddenStaffingRows] = useState([]);
  const [allZeroStaffing, setAllZeroStaffing] = useState(false);

  const [visibleAdditionalRows, setVisibleAdditionalRows] = useState([]);
  const [hiddenAdditionalRows, setHiddenAdditionalRows] = useState([]);
  const [allZeroAdditional, setAllZeroAdditional] = useState(false);
   const [showModal, setShowModal] = useState({
     state: false,
     id: null,
   });

  const containerRef = useRef(null);
  const checkFormFields = async () => {
    return new Promise((resolve, reject) => {
      const sections = ["patient_data", "staffing", "additional_data_tracking"];
      for (const section of sections) {
        for (const item of formControl[section]) {
          // Skip checking item.field_label if item.display is 0
          if (item.display === 0) {
            continue;
          }

          // Check item.field_label if item.display is not 0
          if (!item.field_label || item.field_label.trim() === "") {
            reject(new Error("All Label fields are mandatory"));
            return; // Exit early on failure to prevent further execution
          }
        }
      }
      resolve("All fields are valid");
    });
  };

  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      timeSlots.push(`${formattedHour}:00`);
    }
    return timeSlots;
  }

  const timeSlots = generateTimeSlots();

  console.log(formControl);
  const [initialValues, setInitialValues] = useState({
    cost_center_name: "",
    cost_center_number: "",
    cost_center_category: "",
    business_unit_description: "",
    target: "",
    target_type: "",
    tier2: "",
    tier3: "",
    tier4: "",
    tier5: "",
    uos_description: "",
    realtime: 0,
    customer_code: "",
    fixed_or_variable: "",
    standard_unit_ratio: "0",
    facility_id: "",
    facility_masters: "",
    director: "",
    manager: "",
    is10day: "0",
    adm: "0",
    adm: "0",
    biweekly: "0",
    validate_census: "0",
    accepted_acuity_per_nurse: "",
    first_entry: "03:00",
    interval: "4",
    warm_up: 30,
    delay_time: 60,
    budget1: {
      beds: "0",
      fte_max: "0",
      fte_min: "0",
      nurse_uos: "0",
      required_fte: "0",
    },
    patient_data_label: "",
    staffing_data_label: "",
    additional_data_label: "",
    number_of_days: "",
    census_grid: "",
    show_productivity_ring: "0",
    show_compliance_ring: "",
    overtime_threshold: "",
    display_on_nursing_status: "",
    status: "",
    is_ambulatory: "",
  });

  function removeDuplicates(data) {
    function filterUniqueByFieldName(array) {
      const seen = new Set();
      return array.filter((item) => {
        const duplicate = seen.has(item.field_name);
        seen.add(item.field_name);
        return !duplicate;
      });
    }

    return {
      patient_data: filterUniqueByFieldName(data.patient_data),
      staffing: filterUniqueByFieldName(data.staffing),
      additional_data_tracking: filterUniqueByFieldName(
        data.additional_data_tracking
      ),
    };
  }

  const getInitalData = async () => {
    const resp = await axios.post(`${API_CALL_URL_PRIVATE}/department/detail`, {
      cost_center_number: parseInt(costCenter),
      customer_code: parseInt(customer_code),
    });
    // setcheckedAllFormControll(resp?.data?.data?.checkedAllFormControll);
    const Detail = resp?.data?.data?.department_details;
    // setFormControl(resp?.data?.data?.form_control);
    setRealtimeFormula(resp?.data?.data?.realtime_formula);
    const cleanedData = removeDuplicates(resp?.data?.data?.form_control);
    setFormControl(cleanedData);
    setFormDetailData(resp?.data?.data?.form_control);

    const hdateArray = Detail?.hire_date?.split("/");

    if (hdateArray && hdateArray.length === 3) {
      const [hday, hmonth, hyear] = hdateArray;

      // Check if day, month, and year are valid numbers
      if (!isNaN(hday) && !isNaN(hmonth) && !isNaN(hyear)) {
        setHireDate(new Date(`${hyear}-${hmonth}-${hday}`));
      }
    }

    const tdateArray = Detail?.termination_date?.split("/");
    if (tdateArray && tdateArray.length === 3) {
      const [day, month, year] = tdateArray;

      // Check if day, month, and year are valid numbers
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        setTerminateDate(new Date(`${year}-${month}-${day}`));
      }
    }

    setIsRealtime(Detail?.realtime === 1);
    setInitialValues((prev) => {
      return {
        ...prev,
        cost_center_name: Detail?.cost_center_name,
        cost_center_number: Detail?.cost_center_number,
        cost_center_category: Detail?.cost_center_category,
        business_unit_description: Detail?.business_unit_description,
        target: Detail?.target,
        target_type:
          Detail?.target_type_masters?.find(
            (item) => item.target_type_id === Detail?.target_type
          )?.target_type_id ?? "",
        tier2: Detail?.tier2,
        tier3: Detail?.tier3,
        tier4: Detail?.tier4,
        tier5: Detail?.tier5,
        uos_description: Detail?.uos_description,
        realtime: String(Detail?.realtime),
        customer_code: Detail?.customer_code,
        facility_id: Detail?.facility_masters?.find(
          (item) => item.facility_id === Detail?.facility_id
        )
          ? Detail?.facility_id
          : "",
        // facility_id: Detail?.facility_id,
        facility_masters: Detail?.facility_masters,
        fixed_or_variable: Detail?.fixed_or_variable,
        standard_unit_ratio: String(Detail?.standard_unit_ratio || 0),
        manager: Detail?.manager,
        validate_census: String(Detail?.validate_census),
        director: Detail?.director,
        is10day: Detail?.is10day || "0",
        adm: Detail?.adm || "0",
        biweekly: Detail?.biweekly || "0",
        first_entry: Detail?.first_entry || "03:00",
        interval: Detail?.interval || "4",
        accepted_acuity_per_nurse: Detail?.accepted_acuity_per_nurse,
        patient_data_label: Detail?.patient_data_label,
        staffing_data_label: Detail?.staffing_data_label,
        additional_data_label: Detail?.additional_data_label,
        warm_up: Detail?.warm_up ? Detail.warm_up : 30,
        delay_time: Detail?.delay_time ? Detail.delay_time : 30,
        census_grid: Detail?.census_grid || "",
        number_of_days: Detail.number_of_days
          ? String(Detail.number_of_days)
          : "",
        budget1: {
          beds: String(Detail?.budget1?.beds || 0),
          fte_max: String(Detail?.budget1?.fte_max || 0),
          fte_min: String(Detail?.budget1?.fte_min || 0),
          nurse_uos: String(Detail?.budget1?.nurse_uos || 0),
          required_fte: String(Detail?.budget1?.required_fte || 0),
        },
        show_compliance_ring: String(Detail?.show_compliance_ring),
        show_productivity_ring: String(Detail?.show_productivity_ring),
        overtime_threshold:
          Detail.overtime_threshold && Detail.overtime_threshold !== 0
            ? Detail.overtime_threshold
            : 0.25,
        display_on_nursing_status: String(Detail?.display_on_nursing_status),
        is_ambulatory: String(Detail?.is_ambulatory),
        status: String(Detail?.status),
      };
    });

    setFacilityMaster(Detail?.facility_masters);
    setTargetTypeList(Detail?.target_type_masters);
    setISInitialData(true);

    // setInitialImageUrl(`${DOMAIN_URL}/${Detail?.logo}`);
  };
  const getRealtimeformula = async () => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/getRealTimeFormula`
      );
      setRealtimeFormulaHeaders(resp.data.formula || []);
      // setISInitialData(true);
    } catch (error) {
      console.log(error);
    }
  };
  const getCostCenterCategoryList = async () => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/costcentercategory/list`,
        {
          customer_code: parseInt(customer_code),
          searchKeyword: "",
          sort_column: "_id",
          sort_order: 1,
          page: 1,
          limit: 10000,
        }
      );
      const category_list = resp.data.data.data.docs?.map((e) => {
        return {
          cost_center_category_id: e.cost_center_category_id,
          cost_center_category: e.cost_center_category,
          note: e.note,
        };
      });
      console.log(category_list);
      setCategoryList(category_list);
      // setRealtimeFormulaHeaders(resp.data.formula);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInitalData();
    getRealtimeformula();
    getCostCenterCategoryList();
  }, [ID]);

  const handlePatientSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };

  const handlePatientSort = (fieldName, val) => {
    console.log("fieldName", fieldName);
    console.log("val", val);
    console.log("prevFormControl", formControl);
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      console.log("fieldExists", fieldExists);

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            if (item.field_name === fieldName) {
              if (parseInt(val) === 0) {
                return {
                  ...item,
                  display: parseInt(val),
                  required: parseInt(val),
                };
              } else return { ...item, display: parseInt(val) };
            }
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            field_label: null,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handlePatientLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            return { ...item, field_label: val };
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            field_label: val,
            display: 0,
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handlePatientSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedPatientData = prevFormControl?.patient_data.map((item) => {
        if (item?.field_name === fieldName) {
          if (parseInt(item?.display) > 0) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
        }
        return item;
      });

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handleStaffingSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;

    const person = formControl?.staffing?.find((p) => p?.field_name === val);
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };

  const handleStaffingSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.staffing.some(
        (item) => item.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        updatedStaffingData = prevFormControl.staffing.map((item) => {
          if (item.field_name === fieldName) {
            if (parseInt(val) === 0) {
              return {
                ...item,
                display: parseInt(val),
                required: parseInt(val),
              };
            } else return { ...item, display: parseInt(val) };
          }
          return item;
        });
      } else {
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            field_label: null,
            display: parseInt(val),
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };

  const handleStaffingLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.staffing.some(
        (item) => item.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        updatedStaffingData = prevFormControl.staffing.map((item) => {
          if (item.field_name === fieldName) {
            return { ...item, field_label: val };
          }
          return item;
        });
      } else {
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            field_label: val,
            display: 0, // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };

  const handleStaffingSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedstaffingData = prevFormControl?.staffing.map((item) => {
        if (item?.field_name === fieldName) {
          if (item?.display > 0) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
          // Toggle the display value (0 to 1, or 1 to 0)
        }
        return item;
      });

      return {
        ...prevFormControl,
        staffing: updatedstaffingData,
      };
    });
  };
  
  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }

  const showErrorToast = debounce((message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }, 500);

  const handleAddDataGraph = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const currentCount = prevFormControl.additional_data_tracking.reduce(
        (acc, item) => acc + (item.display_graph === 1 ? 1 : 0),
        0
      );

      const targetItem = prevFormControl.additional_data_tracking.find(
        (item) => item.field_name === fieldName
      );

      const isFieldCurrentlyOne = targetItem?.display_graph === 1;

      if (parseInt(val) === 1 && currentCount >= 4 && !isFieldCurrentlyOne) {
        showErrorToast("Only 4 items can have 'Display Graph' set to Yes.");
        return prevFormControl;
      }

      const updatedAddDataData = prevFormControl.additional_data_tracking.map(
        (item) => {
          if (item.field_name === fieldName) {
            const displayGraphValue = item.display > 0 ? parseInt(val) : 0;
            return { ...item, display_graph: displayGraphValue };
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };

  const handleAddDataSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.additional_data_tracking.some(
        (item) => item.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        updatedAddDataData = prevFormControl.additional_data_tracking.map(
          (item) => {
            if (item.field_name === fieldName) {
              if (item.field_name === fieldName) {
                if (parseInt(val) === 0) {
                  return {
                    ...item,
                    display: parseInt(val),
                    required: parseInt(val),
                    display_graph: parseInt(val),
                  };
                } else return { ...item, display: parseInt(val) };
              }
            }
            return item;
          }
        );
      } else {
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            field_label: null,
            display_graph: 0,
            display: parseInt(val),
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };

  const handleAdditionalLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.additional_data_tracking.some(
        (item) => item.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        updatedAddDataData = prevFormControl.additional_data_tracking.map(
          (item) => {
            if (item.field_name === fieldName) {
              return { ...item, field_label: val };
            }
            return item;
          }
        );
      } else {
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            field_label: val,
            display_graph: 0,
            display: 0, // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };

  const handleAddDataSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;

    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };

  const handleDisplayGraphSwitchcheck = (val) => {
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    return parseInt(person?.display_graph) === 1 ? true : false;
  };

  const handleAddDataSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedAddData = prevFormControl?.additional_data_tracking.map(
        (item) => {
          if (item?.field_name === fieldName) {
            if (item?.display > 0) {
              return { ...item, required: parseInt(val ? "1" : "0") };
            } else {
              return { ...item, required: 0 };
            }
            // Toggle the display value (0 to 1, or 1 to 0)
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddData,
      };
    });
  };

  const onSubmit = async (values, onSubmittingProps) => {
    if (parseInt(values.realtime) === 1) {
      try {
        await checkFormFields();
        formSubmit(values);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } else {
      formSubmit(values);
    }
  };
  
  const formSubmit = async (values) => {
    console.log("subform", formControl);
    const obj = {
      _id: ID,
      cost_center_name: values.cost_center_name,
      cost_center_number: parseInt(values.cost_center_number),
      cost_center_category:
        values.cost_center_category !== ""
          ? parseInt(values.cost_center_category)
          : null,
      business_unit_description: values.business_unit_description,
      target: parseFloat(values.target),
      target_type: Number(values.target_type),
      tier2: values.tier2,
      tier3: values.tier3,
      tier4: values.tier4,
      tier5: values.tier5,
      uos_description: values.uos_description,
      realtime: parseInt(values.realtime) || 0 ,
      customer_code: parseInt(values.customer_code),
      facility_id: parseInt(values.facility_id),
      fixed_or_variable: values.fixed_or_variable,
      standard_unit_ratio: parseInt(values.standard_unit_ratio),
      form_control: formControl,
      manager: values.manager,
      director: values.director,
      is10day: parseInt(values.is10day),
      adm: parseInt(values.adm) || "0",
      biweekly: parseInt(values.biweekly) || "0",
      first_entry: values.first_entry,
      validate_census: parseInt(values.validate_census),
      interval: parseInt(values.interval),
      patient_data_label: values.patient_data_label,
      staffing_data_label: values.staffing_data_label,
      additional_data_label: values.additional_data_label,
      warm_up: parseInt(values.warm_up),
      delay_time: parseInt(values.delay_time),
      census_grid: values.census_grid,
      number_of_days: parseInt(values.number_of_days),
      show_productivity_ring: parseInt(values.show_productivity_ring) || "0",
      show_compliance_ring: parseInt(values.show_compliance_ring) || "0",
      display_on_nursing_status: parseInt(values.display_on_nursing_status) || "0",
      is_ambulatory: parseInt(values.is_ambulatory),
      status: parseInt(values.status),
      budget1: {
        beds: parseInt(values.budget1.beds),
        fte_max: parseInt(values.budget1.fte_max),
        fte_min: parseInt(values.budget1.fte_min),
        nurse_uos: parseInt(values.budget1.nurse_uos),
        required_fte: parseInt(values.budget1.required_fte),
      },
      accepted_acuity_per_nurse:
        values.accepted_acuity_per_nurse !== ""
          ? parseFloat(values.accepted_acuity_per_nurse)
          : 0,
      overtime_threshold:
        values.overtime_threshold !== ""
          ? parseFloat(values.overtime_threshold)
          : 0,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      getInitalData();
      getRealtimeformula();
      getCostCenterCategoryList();
      setShowAllPatient(false);
      setShowAllStaffing(false);
      setShowAllAdditional(false);
    } catch (error) {
      toast.error(error.response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

   const disabledFields = [
     "fixed_position_5_1",
     "fixed_position_5_2",
     "fixed_position_5_3",
     "fixed_position_5_4",
     "fixed_position_5_5",
     "fixed_position_5_8",
     "fixed_position_5_5_2",
     "fixed_position_7_1",
     "fixed_position_7_2",
     "fixed_position_7_3",
     "fixed_position_7_4",
     "fixed_position_7_5",
     "fixed_position_7_5_2",
     "fixed_position_7_8",
   ];

    const hideDisplayGraph = [
      "hmc_main_or_minutes",
      "hmc_tops_or_minutes",
      "hmc_peri_anesthesia_minutes",
      "hmc_surgical_minutes",
      "hmc_main_or_preop_minutes",
      "hmc_tops_recovery_minutes",
      "hmc_tops_recovery_for_main_or_pts",
      "hmc_tops_total_minutes",
    ];


  // Patient Data
  const sortAndFilterPatientData = () => {
    const sortedPatient = formControl?.patient_data
      ?.slice()
      ?.sort((a, b) => a.display - b.display);
    setSortedPatientData(sortedPatient);

    // Filter visible and hidden rows
    const visibleRows = sortedPatient?.filter((item) => item.display !== 0);
    const hiddenRows = sortedPatient?.filter((item) => item.display === 0);

    const allZero = visibleRows?.length === 0;

    if (allZero) {
      setVisiblePatientRows(sortedPatient);
    } else {
      setVisiblePatientRows(visibleRows);
    }
    setHiddenPatientRows(hiddenRows);
    setAllZeroPatient(allZero);
  };

  console.log("allzero", allZeroPatient);
  

 useEffect(() => {
   if (formDetailData) {
     sortAndFilterPatientData();
     sortAndFilterStaffingData();
     sortAndFilterAdditionalData();
   }
 }, [formDetailData]); 

 const toggleShowMorePatient = () => {
   setShowAllPatient((prevShowAll) => {
     // Sort the patient data
     const sortedPatient = formControl?.patient_data
       ?.slice()
       ?.sort((a, b) => a.display - b.display);

     setSortedPatientData(sortedPatient);

     // Get visible and hidden rows
     const visibleRows = sortedPatient?.filter((item) => item.display !== 0);
     const hiddenRows = sortedPatient?.filter((item) => item.display === 0);

     // Determine which rows to show based on the updated showAllPatient state
     if (!prevShowAll) {
       // If previous state is false, we're now showing all (true), so show both visible and hidden rows
       setVisiblePatientRows([...visibleRows, ...hiddenRows]);
     } else {
       // If previous state is true, we're now hiding some (false), so show only visible rows
       setVisiblePatientRows(visibleRows);
     }

     return !prevShowAll; // Finally toggle the state
   });
 };

  console.log("visiblePatient", visiblePatientRows);
  

  // Staffing Data
  const sortAndFilterStaffingData = () => {
    const sortedStaffingData = formDetailData?.staffing
      ?.slice()
      ?.sort((a, b) => a.display - b.display);

    const visibleStaffingRows = sortedStaffingData?.filter((item) => {
      if (disabledFields.includes(item.field_name)) {
        return item.field_label !== null;
      }
      return item.display !== 0;
    });

     const hiddenStaffingRows = sortedStaffingData?.filter((item) => {
       if (disabledFields.includes(item.field_name)) {
         return item.field_label === null;
       }
       return item.display === 0;
     });

    const allZeroStaffing = visibleStaffingRows?.length === 0;

      if (allZeroStaffing) {
        setVisibleStaffingRows(sortedStaffingData);
      } else {
        setVisibleStaffingRows(visibleStaffingRows);
      }
        setHiddenStaffingRows(hiddenStaffingRows);
        setAllZeroStaffing(allZeroStaffing);

  };

  // const {
  //   visibleStaffingRows,
  //   hiddenStaffingRows,
  //   allZeroStaffing,
  //   sortedStaffingData,
  // } = formDetailData && sortAndFilterStaffingData();

  const toggleShowMoreStaffing = () => {
    // setShowAllStaffing((prevShowAll) => !prevShowAll);

    setShowAllStaffing((prevShowAll) => {
      const sortedStaffingData = formDetailData?.staffing
        ?.slice()
        ?.sort((a, b) => a.display - b.display);

      const visibleStaffingRows = sortedStaffingData?.filter((item) => {
        if (disabledFields.includes(item.field_name)) {
          return item.field_label !== null;
        }
        return item.display !== 0;
      });

      const hiddenStaffingRows = sortedStaffingData?.filter((item) => {
        if (disabledFields.includes(item.field_name)) {
          return item.field_label === null;
        }
        return item.display === 0;
      });


      if (!prevShowAll) {
        // If previous state is false, we're now showing all (true), so show both visible and hidden rows
        setVisibleStaffingRows([...visibleStaffingRows, ...hiddenStaffingRows]);
      } else {
        // If previous state is true, we're now hiding some (false), so show only visible rows
        setVisibleStaffingRows(visibleStaffingRows);
      }

      return !prevShowAll; // Finally toggle the state
    });
  };

  // Additional Data
   const sortAndFilterAdditionalData = () => {
     const sortedAdditionalData = formDetailData?.additional_data_tracking
       ?.slice()
       ?.sort((a, b) => a.display - b.display);

     const visibleAdditionalRows = sortedAdditionalData?.filter(
       (item) => item.display !== 0
     );
     const hiddenAdditionalRows = sortedAdditionalData?.filter(
       (item) => item.display === 0
     );

     const allZeroAdditional = visibleAdditionalRows?.length === 0;

      if (allZeroAdditional) {
        setVisibleAdditionalRows(visibleAdditionalRows);
      } else {
        setVisibleAdditionalRows(visibleAdditionalRows);
      }
      setHiddenAdditionalRows(hiddenAdditionalRows);
      setAllZeroAdditional(allZeroAdditional);

   };

   const toggleShowMoreAdditional = () => {

      setShowAllAdditional((prevShowAll) => {
        // Sort the patient data
       const sortedAdditionalData = formDetailData?.additional_data_tracking
         ?.slice()
         ?.sort((a, b) => a.display - b.display);

       const visibleAdditionalRows = sortedAdditionalData?.filter(
         (item) => item.display !== 0
       );
       const hiddenAdditionalRows = sortedAdditionalData?.filter(
         (item) => item.display === 0
       );
        // Determine which rows to show based on the updated showAllPatient state
        if (!prevShowAll) {
          // If previous state is false, we're now showing all (true), so show both visible and hidden rows
          setVisibleAdditionalRows([
            ...visibleAdditionalRows,
            ...hiddenAdditionalRows,
          ]);
        } else {
          // If previous state is true, we're now hiding some (false), so show only visible rows
          setVisibleAdditionalRows(visibleAdditionalRows);
        }

        return !prevShowAll; // Finally toggle the state
      });

   };


   // Drag and Drop Implementation Patient
    const handleDragEndPatientVisible = (result) => {

      if (!result.destination) return;


      const items = Array.from(visiblePatientRows);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedVisibleRows = items.map((item, index) => ({
        
        ...item,
        display: item.display === 0 ? 0 : index + 1,
      }));

      //  const updatedVisibleRows = items.map((item, index) => {
      //    // Check if the item is the one that was dragged and dropped
      //    if (item._id === reorderedItem._id) {
      //      return {
      //        ...item,
      //        display: index + 1, // Assign index + 1 regardless of the previous display value
      //      };
      //    }

      //    return {
      //      ...item,
      //      display: item.display === 0 ? 0 : index + 1, // Apply the original condition for other items
      //    };
      //  });

      setVisiblePatientRows(updatedVisibleRows);

      setFormControl((prevFormControl) => {
        const updatedPatientData = prevFormControl?.patient_data.map((item) => {
          const matchedVisibleItem = updatedVisibleRows.find(
            (visibleItem) => visibleItem._id === item._id
          );

          if (matchedVisibleItem) {
            return { ...item, display: matchedVisibleItem.display };
          }

          return item;
        });

        return {
          ...prevFormControl,
          patient_data: updatedPatientData,
        };
      });
    };


    const handleCheckboxPatient = (fieldName, isChecked) => {
      setFormControl((prevFormControl) => {
        const visibleRows = prevFormControl.patient_data.filter(
          (item) => item.display !== 0
        );

        const updatedPatientData = prevFormControl.patient_data.map((item) =>
          item.field_name === fieldName
            ? {
                ...item,
                display: isChecked ? visibleRows.length + 1 : 0, // update display
                required : isChecked ? 0 : 0,
                display_graph: isChecked ? 0 : 0,
              }
            : item
        );
        
        return {
          ...prevFormControl,
          patient_data: updatedPatientData,
        };
      });

      const visibleRowsP = formControl?.patient_data.filter(
        (item) => item.display !== 0
      );

        const updatedVisiblePatientData = visiblePatientRows?.map((item) =>
          item?.field_name === fieldName
            ? {
                ...item,
                display: isChecked ? visibleRowsP?.length + 1 : 0, // update display
                required : isChecked ? 0 : 0,
                display_graph: isChecked ? 0 : 0,
              }
            : item
        );

        setVisiblePatientRows(updatedVisiblePatientData);


    };
    

    const handlePatientDisplayGraphSwitchcheck = (val, type) => {
      // Dynamically find the person based on the type
      const person =
        type === "additional_data_tracking"
          ? formControl?.additional_data_tracking?.find(
              (p) => p?.field_name === val
            )
          : type === "patient_data"
          ? formControl?.patient_data?.find((p) => p?.field_name === val)
          : formControl?.staffing?.find((p) => p?.field_name === val);

      return parseInt(person?.display) > 0 &&
        parseInt(person?.display_graph) === 1
        ? true
        : false;
    };


    const handlePatientAddDataGraph = (fieldName, val, type) => {
      setFormControl((prevFormControl) => {
        // Count how many items across all sections have display_graph set to 1
        const totalCount = [
          ...prevFormControl.additional_data_tracking,
          ...prevFormControl.patient_data,
          ...prevFormControl.staffing,
        ]?.reduce((acc, item) => acc + (item.display_graph === 1 ? 1 : 0), 0);

        console.log("totalCount", totalCount);

        // Find the target item based on the type
        const targetItem =
          type === "additional_data_tracking"
            ? prevFormControl?.additional_data_tracking.find(
                (item) => item.field_name === fieldName
              )
            : type === "patient_data"
            ? prevFormControl?.patient_data.find(
                (item) => item.field_name === fieldName
              )
            : prevFormControl?.staffing.find(
                (item) => item.field_name === fieldName
              );

        // Check if the field is currently set to 1
        const isFieldCurrentlyOne = targetItem?.display_graph === 1;
        console.log("isFieldCurrentlyOne", isFieldCurrentlyOne);

        // If setting to 1, check if totalCount >= 4 and the field is not already 1
        if (parseInt(val) === 1 && totalCount >= 4 && !isFieldCurrentlyOne) {
          showErrorToast("Only 4 items can have 'Display Graph' set to Yes.");
          return prevFormControl;
        }

        // Update the specific section based on the type
        const updateSection = (section) =>
          section?.map((item) => {
            if (item.field_name === fieldName) {
              const displayGraphValue = item.display > 0 ? parseInt(val) : 0;
              return { ...item, display_graph: displayGraphValue };
            }
            return item;
          });

        // Update the formControl state based on the type
        return {
          ...prevFormControl,
          [type]: updateSection(prevFormControl[type]), // Dynamically update the correct section
        };
      });
    };


    // Drag and Drop Implementation Staffing

    const handleDragEndStaffingVisible = (result) => {
      if (!result.destination) return;

      const items = Array.from(visibleStaffingRows);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedVisibleRows = items.map((item, index) => ({
        ...item,
        display: item.display === 0 ? 0 : index + 1,
      }));

      setVisibleStaffingRows(updatedVisibleRows);

      setFormControl((prevFormControl) => {
        const updatedStaffingData = prevFormControl?.staffing?.map(
          (item) => {
            const matchedVisibleItem = updatedVisibleRows.find(
              (visibleItem) => visibleItem._id === item._id
            );

            if (matchedVisibleItem) {
              return { ...item, display: matchedVisibleItem.display };
            }

            return item;
          }
        );

        return {
          ...prevFormControl,
          staffing: updatedStaffingData,
        };
      });
    };

    const handleCheckboxStaffing = (fieldName, isChecked) => {

      if (disabledFields.includes(fieldName)) {
        return;
      }
      
      setFormControl((prevFormControl) => {
        const visibleRows = prevFormControl.staffing.filter(
          (item) => item.display !== 0
        );

        const updatedStaffingData = prevFormControl.staffing.map((item) =>
          item.field_name === fieldName
            ? {
                ...item,
                display: isChecked ? visibleRows.length + 1 : 0, // update display
                required : isChecked ? 0 : 0,
                display_graph: isChecked ? 0 : 0,
              }
            : item
        );

        return {
          ...prevFormControl,
          staffing: updatedStaffingData,
        };
      });

      const visibleStaffingTwo = formControl?.staffing.filter(
        (item) => item.display !== 0
      );

      const updatedVisibleStaffingData = visibleStaffingRows?.map((item) =>
        item?.field_name === fieldName
          ? {
              ...item,
              display: isChecked ? visibleStaffingTwo?.length + 1 : 0, // update display
              required : isChecked ? 0 : 0,
              display_graph: isChecked ? 0 : 0,
            }
          : item
      );

      setVisibleStaffingRows(updatedVisibleStaffingData);
    };


    // Drag and Drop Implementation Additional

    const handleDragEndAdditionalVisible = (result) => {
      if (!result.destination) return;

      const items = Array.from(visibleAdditionalRows);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedVisibleRows = items.map((item, index) => ({
        ...item,
        display: item.display === 0 ? 0 : index + 1,
      }));

      setVisibleAdditionalRows(updatedVisibleRows);

      setFormControl((prevFormControl) => {
        const updatedAdditionalData =
          prevFormControl?.additional_data_tracking?.map((item) => {
            const matchedVisibleItem = updatedVisibleRows.find(
              (visibleItem) => visibleItem._id === item._id
            );

            if (matchedVisibleItem) {
              return { ...item, display: matchedVisibleItem.display };
            }

            return item;
          });

        return {
          ...prevFormControl,
          additional_data_tracking: updatedAdditionalData,
        };
      });
    };

    const handleCheckboxAdditional = (fieldName, isChecked) => {

      setFormControl((prevFormControl) => {
        const visibleRows = prevFormControl?.additional_data_tracking?.filter(
          (item) => item.display !== 0
        );

        const updatedAdditionalData = prevFormControl.additional_data_tracking.map((item) =>
          item.field_name === fieldName
            ? {
                ...item,
                display: isChecked ? visibleRows.length + 1 : 0, // update display
                   required : isChecked ? 0 : 0,
                display_graph: isChecked ? 0 : 0,
              }
            : item
        );

        return {
          ...prevFormControl,
          additional_data_tracking: updatedAdditionalData,
        };
      });

      const visibleAdditionalTwo = formControl?.additional_data_tracking.filter(
        (item) => item.display !== 0
      );

      const updatedVisibleAdditionalData = visibleAdditionalRows?.map((item) =>
        item?.field_name === fieldName
          ? {
              ...item,
              display: isChecked ? visibleAdditionalTwo?.length + 1 : 0, // update display
              required : isChecked ? 0 : 0,
              display_graph: isChecked ? 0 : 0,
            }
          : item
      );

      setVisibleAdditionalRows(updatedVisibleAdditionalData);
    };


    console.log("vipo", visiblePatientRows);

    console.log("formControlct", formControl);
    
    



  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={departmentValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel ">
              {console.log(formik)}
              <div className="content-wrapper ">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row ">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/department`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Update details for :{" "}
                                {initialValues?.cost_center_name}
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>
                                Cost center number
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_number"
                                value={formik.values.cost_center_number}
                                readonly
                              />
                              <ErrorMessage
                                name="cost_center_number"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>
                                Cost Center Name
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_name"
                                {...formik.getFieldProps("cost_center_name")}
                              />
                              <ErrorMessage
                                name="cost_center_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Director</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="director"
                                {...formik.getFieldProps("director")}
                              />
                              <ErrorMessage
                                name="director"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Manager</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="manager"
                                {...formik.getFieldProps("manager")}
                              />
                              <ErrorMessage
                                name="manager"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3 position-relative"
                              controlId="exampleForm.c_email"
                            >
                              {/* {console.log(categoryList?.find(
                                  (e) =>
                                    e.cost_center_category_id ===
                                    formik.values.cost_center_category
                                )?.cost_center_category_id)}  */}
                              {console.log(categoryList)}
                              <Form.Label>Cost center category</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="cost_center_category"
                                // value={`${formik.values.cost_center_category}`}
                                value={
                                  categoryList?.find(
                                    (e) =>
                                      e.cost_center_category_id ===
                                      parseInt(
                                        formik.values.cost_center_category
                                      )
                                  )?.cost_center_category_id
                                }
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "cost_center_category",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "cost_center_category",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value={""}>select</option>
                                {categoryList?.map((e) => {
                                  return (
                                    <option
                                      key={e?.cost_center_category}
                                      value={e?.cost_center_category_id}
                                    >
                                      {e?.cost_center_category}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="cost_center_category"
                                component={Texterror}
                              />
                              <span
                                className="position-absolute"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  textAlign: "left",
                                  lineHeight: "43px",
                                  top: "26px",
                                  right: "52px",
                                }}
                              >
                                <>
                                  {
                                    <OverlayTrigger
                                      key={"top"}
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <strong>{"Note:"}</strong>
                                          &nbsp;
                                          {
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  categoryList?.find(
                                                    (e) =>
                                                      e?.cost_center_category_id ===
                                                      parseInt(
                                                        formik.values
                                                          .cost_center_category
                                                      )
                                                  )?.note || "N/A",
                                              }}
                                            />
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={calcIcon}
                                        alt=""
                                        style={{
                                          width: "30px",
                                          height: "auto",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </OverlayTrigger>
                                  }
                                </>
                              </span>
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Business Unit Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="business_unit_description"
                                {...formik.getFieldProps(
                                  "business_unit_description"
                                )}
                              />
                              <ErrorMessage
                                name="business_unit_description"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Target
                                <span style={{ color: "red" }}>&nbsp;*</span>
                                {process.env.React_App_ENVIRONMENT !==
                                  "production" && (
                                  <Button
                                    style={{
                                      fontSize: "0.6rem",
                                      padding: "3px 7px",
                                    }}
                                    variant="secondary"
                                    className="mx-3"
                                    onClick={() => {
                                      setShowModal({
                                        state: true,
                                        // id: Ele._id,
                                      });
                                    }}
                                    // className="ms-3"
                                    // variant="success"
                                  >
                                    Target History
                                  </Button>
                                )}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target"
                                {...formik.getFieldProps("target")}
                              />
                              <ErrorMessage
                                name="target"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Target type</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="target_type"
                                value={`${formik.values.target_type}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "target_type",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "target_type",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value={""}>select</option>
                                {targettypeList?.map((e) => {
                                  return (
                                    <option
                                      key={e?.target_type}
                                      value={e?.target_type_id}
                                    >
                                      {e?.target_type}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="target_type"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 2</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier2"
                                {...formik.getFieldProps("tier2")}
                              />
                              <ErrorMessage
                                name="tier2"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 3</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier3"
                                {...formik.getFieldProps("tier3")}
                              />
                              <ErrorMessage
                                name="tier3"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 4</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier4"
                                {...formik.getFieldProps("tier4")}
                              />
                              <ErrorMessage
                                name="tier4"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 5</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier5"
                                {...formik.getFieldProps("tier5")}
                              />
                              <ErrorMessage
                                name="tier5"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Accepted Acuity per Nurse</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="accepted_acuity_per_nurse"
                                {...formik.getFieldProps(
                                  "accepted_acuity_per_nurse"
                                )}
                              />
                              <ErrorMessage
                                name="accepted_acuity_per_nurse"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>UOS Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="uos_description"
                                {...formik.getFieldProps("uos_description")}
                              />
                              <ErrorMessage
                                name="uos_description"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Realtime</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="realtime"
                                value={`${formik.values.realtime}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "realtime",
                                    e.target.value
                                  );
                                  setIsRealtime(parseInt(e.target.value) === 1);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="realtime"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>ADM</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="adm"
                                value={`${formik.values.adm}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue("adm", e.target.value);
                                  // setIsRealtime(parseInt(e.target.value) === 1);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage name="adm" component={Texterror} />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Biweekly</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="biweekly"
                                value={`${formik.values.biweekly}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "biweekly",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Facility Masters
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="facility_id"
                                value={`${formik.values.facility_id}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "facility_id",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "facility_id",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value={""}>Select</option>
                                {facilityMaster?.map((e) => {
                                  return (
                                    <option
                                      key={e?.facility_id}
                                      value={e?.facility_id}
                                    >
                                      {e?.facility_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="facility_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Fixed or Variable
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="fixed_or_variable"
                                value={`${formik.values.fixed_or_variable}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "fixed_or_variable",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "fixed_or_variable",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "fixed_or_variable",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">Select</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Variable">Variable</option>
                              </Form.Select>
                              <ErrorMessage
                                name="fixed_or_variable"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Is10day</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="is10day"
                                value={`${formik.values.is10day}`}
                                onChange={(e) => {
                                  formik.setFieldTouched("is10day", true, true);
                                  formik.setFieldValue(
                                    "is10day",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("is10day", true, true);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="is10day"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Standard Unit Ratio</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="standard_unit_ratio"
                                value={`${formik.values.standard_unit_ratio}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "standard_unit_ratio",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "standard_unit_ratio",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "standard_unit_ratio",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">Select</option>
                                <option value="2">1:2</option>
                                <option value="3">1:3</option>
                                <option value="4">1:4</option>
                                <option value="5">1:5</option>
                              </Form.Select>
                              <ErrorMessage
                                name="standard_unit_ratio"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Validate Census</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="validate_census"
                                value={`${formik.values.validate_census}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "validate_census",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "validate_census",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "validate_census",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="validate_census"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Warm Up (In Minutes)</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="warm_up"
                                {...formik.getFieldProps("warm_up")}
                              />
                              <ErrorMessage
                                name="warm_up"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Delayed Entry Threshold (In Minutes)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="delay_time"
                                {...formik.getFieldProps("delay_time")}
                              />
                              <ErrorMessage
                                name="delay_time"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                No of Days
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="validate_census"
                                value={`${formik.values.number_of_days}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "number_of_days",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "number_of_days",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "number_of_days",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                              </Form.Select>
                              <ErrorMessage
                                name="number_of_days"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Census Grid
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="census_grid"
                                value={`${formik.values.census_grid}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "census_grid",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "census_grid",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "census_grid",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="census_grid"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Show Productivity Ring</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_productivity_ring"
                                value={`${formik.values.show_productivity_ring}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "show_productivity_ring",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "show_productivity_ring",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "show_productivity_ring",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_productivity_ring"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Show Compliance Ring</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_compliance_ring"
                                value={`${formik.values.show_compliance_ring}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "show_compliance_ring",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "show_compliance_ring",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "show_compliance_ring",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_compliance_ring"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Overtime Threshold</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="overtime_threshold"
                                {...formik.getFieldProps("overtime_threshold")}
                              />
                              <ErrorMessage
                                name="overtime_threshold"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Display on Nursing Status Page
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="display_on_nursing_status"
                                value={`${formik.values.display_on_nursing_status}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "display_on_nursing_status",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "display_on_nursing_status",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "display_on_nursing_status",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="display_on_nursing_status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="status"
                                value={`${formik.values.status}`}
                                onChange={(e) => {
                                  formik.setFieldTouched("status", true, true);
                                  formik.setFieldValue(
                                    "status",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("status", true, true);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                              </Form.Select>
                              <ErrorMessage
                                name="status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Ambulatory</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="is_ambulatory"
                                value={`${formik.values.is_ambulatory}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "is_ambulatory",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "is_ambulatory",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "is_ambulatory",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="is_ambulatory"
                                component={Texterror}
                              />
                            </Form.Group>
                            <div>
                              <div className="row mt-2">
                                <div class="col-12 grid-margin stretch-card">
                                  <div class="card">
                                    <div class="card-body py-4">
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                          <h4 class="card-title fs-5 mb-0">
                                            Budget 1
                                          </h4>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div className="col-md-12 m-0">
                                          <hr />
                                        </div>
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col mb-3">
                                              <Form.Group controlId="exampleForm.c_code">
                                                <Form.Label>
                                                  Beds
                                                  {/* <span style={{ color: "red" }}>
                                                &nbsp;*
                                              </span> */}
                                                </Form.Label>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  name="budget1.beds"
                                                  getFacilityMaster
                                                  // {...formik.getFieldProps(
                                                  //   "budget1.beds"
                                                  // )}
                                                  value={
                                                    formik.values.budget1.beds
                                                  }
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "budget1.beds",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "budget1.beds",
                                                      String(e.target.value)
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "budget1.beds",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="budget1.beds"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                            </div>

                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>Fte Max</Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.fte_max"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.fte_max"
                                                // )}
                                                value={
                                                  formik.values.budget1.fte_max
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_max",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.fte_max",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_max",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.fte_max"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>Fte Min</Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.fte_min"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.fte_min"
                                                // )}
                                                value={
                                                  formik.values.budget1.fte_min
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_min",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.fte_min",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_min",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.fte_min"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>Nurse Uos</Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.nurse_uos"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.nurse_uos"
                                                // )}
                                                value={
                                                  formik.values.budget1
                                                    .nurse_uos
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.nurse_uos",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.nurse_uos",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.nurse_uos",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.nurse_uos"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>
                                                Required Fte
                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.required_fte"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.required_fte"
                                                // )}
                                                value={
                                                  formik.values.budget1
                                                    .required_fte
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.required_fte",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.required_fte",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.required_fte",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.required_fte"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {isRealtime && (
                              <>
                                <div>
                                  <div>
                                    <div className="row mt-2">
                                      <div class="col-12 grid-margin stretch-card">
                                        <div class="card">
                                          <div class="card-body py-4">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex">
                                                <h4 class="card-title fs-5 mb-0">
                                                  Shift Interval Configuration
                                                </h4>
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_email"
                                                  >
                                                    <Form.Label>
                                                      First Entry
                                                    </Form.Label>
                                                    <Form.Select
                                                      aria-label="Default select example"
                                                      className="white"
                                                      name="first_entry"
                                                      value={`${formik.values.first_entry}`}
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "first_entry",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "first_entry",
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "first_entry",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                      // onChange={formik.handleChange}
                                                    >
                                                      {timeSlots?.map((e) => {
                                                        return (
                                                          <option
                                                            key={e}
                                                            value={e}
                                                          >
                                                            {e}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                    <ErrorMessage
                                                      name="first_entry"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_email"
                                                  >
                                                    <Form.Label>
                                                      Duration (in hours)
                                                    </Form.Label>
                                                    <Form.Select
                                                      aria-label="Default select example"
                                                      className="white"
                                                      name="interval"
                                                      value={`${formik.values.interval}`}
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "interval",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "interval",
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "interval",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                      // onChange={formik.handleChange}
                                                    >
                                                      {Array.from(
                                                        { length: 24 },
                                                        (x, i) => i + 1
                                                      )?.map((e) => {
                                                        return (
                                                          <option
                                                            key={e}
                                                            value={e}
                                                          >
                                                            {e}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                    <ErrorMessage
                                                      name="interval"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_code"
                                                  >
                                                    <Form.Label>
                                                      Patient Data Label
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      placeholder=""
                                                      name="patient_data_label"
                                                      // {...formik.getFieldProps(
                                                      //   "budget1.required_fte"
                                                      // )}
                                                      value={
                                                        formik.values
                                                          .patient_data_label
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "patient_data_label",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "patient_data_label",
                                                          String(e.target.value)
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "patient_data_label",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name="patient_data_label"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_code"
                                                  >
                                                    <Form.Label>
                                                      Staffing Data Label
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      placeholder=""
                                                      name="staffing_data_label"
                                                      // {...formik.getFieldProps(
                                                      //   "budget1.required_fte"
                                                      // )}
                                                      value={
                                                        formik.values
                                                          .staffing_data_label
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "staffing_data_label",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "staffing_data_label",
                                                          String(e.target.value)
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "staffing_data_label",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name="staffing_data_label"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_code"
                                                  >
                                                    <Form.Label>
                                                      Additional Data Label
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      placeholder=""
                                                      name="additional_data_label"
                                                      // {...formik.getFieldProps(
                                                      //   "budget1.required_fte"
                                                      // )}
                                                      value={
                                                        formik.values
                                                          .additional_data_label
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "additional_data_label",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "additional_data_label",
                                                          String(e.target.value)
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "additional_data_label",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name="additional_data_label"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div className="row mt-2">
                                    <div class="col-12 grid-margin stretch-card">
                                      <Accordion
                                        className="mb-2"
                                        // defaultActiveKey="0"
                                      >
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            Patient Data
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <div
                                                      class="table-responsive"
                                                      ref={containerRef}
                                                      style={{
                                                        maxHeight: "800px",
                                                        overflowY: "auto",
                                                      }}
                                                    >
                                                      <DragDropContext
                                                        onDragEnd={
                                                          handleDragEndPatientVisible
                                                        }
                                                      >
                                                        <Droppable droppableId="visible-data-table">
                                                          {(provided) => (
                                                            <table
                                                              className="table"
                                                              {...provided.droppableProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              style={{
                                                                tableLayout:
                                                                  "fixed",
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <thead>
                                                                <tr>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "5%",
                                                                    }}
                                                                  ></th>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "10%",
                                                                    }}
                                                                  >
                                                                    Show/Hide
                                                                  </th>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "5%",
                                                                    }}
                                                                  ></th>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "30%",
                                                                    }}
                                                                  >
                                                                    Key
                                                                  </th>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "30%",
                                                                    }}
                                                                  >
                                                                    Label
                                                                  </th>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "10%",
                                                                    }}
                                                                  >
                                                                    Display
                                                                    Graph
                                                                  </th>
                                                                  <th
                                                                    style={{
                                                                      width:
                                                                        "10%",
                                                                    }}
                                                                  >
                                                                    Mandatory
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {visiblePatientRows?.map(
                                                                  (
                                                                    val,
                                                                    ind
                                                                  ) => {
                                                                    const foundPatientData =
                                                                      formControl?.patient_data?.find(
                                                                        (e) =>
                                                                          e?.field_name ===
                                                                          val?.field_name
                                                                      );
                                                                    return (
                                                                      <Draggable
                                                                        key={
                                                                          val?._id
                                                                        }
                                                                        draggableId={
                                                                          val?._id
                                                                        }
                                                                        index={
                                                                          ind
                                                                        }
                                                                      >
                                                                        {(
                                                                          provided,
                                                                          snapshot
                                                                        ) => (
                                                                          <tr
                                                                            key={
                                                                              ind
                                                                            }
                                                                            ref={
                                                                              provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            className={`drag-row ${
                                                                              snapshot.isDragging
                                                                                ? "dragging"
                                                                                : ""
                                                                            }`}
                                                                            style={{
                                                                              ...provided
                                                                                .draggableProps
                                                                                .style,
                                                                              boxShadow:
                                                                                snapshot.isDragging
                                                                                  ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                                                                                  : "none",
                                                                              tableLayout:
                                                                                "fixed",
                                                                            }}
                                                                          >
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "5%",
                                                                              }}
                                                                            >
                                                                              <img
                                                                                src={
                                                                                  themeMode.theme ===
                                                                                  "DARK"
                                                                                    ? dragDark
                                                                                    : dragWhite
                                                                                }
                                                                                alt=""
                                                                                {...provided.dragHandleProps}
                                                                                style={{
                                                                                  width:
                                                                                    "15px",
                                                                                  height:
                                                                                    "auto",
                                                                                  cursor:
                                                                                    "grab",
                                                                                }}
                                                                              />
                                                                            </td>
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "10%",
                                                                              }}
                                                                              className="sm-transparent-input"
                                                                              key={
                                                                                ind
                                                                              }
                                                                            >
                                                                              <div className="checkbox-item">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  checked={
                                                                                    foundPatientData?.display >
                                                                                    0
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleCheckboxPatient(
                                                                                      foundPatientData.field_name,
                                                                                      e
                                                                                        .target
                                                                                        .checked
                                                                                    )
                                                                                  }
                                                                                  className="custom-checkbox"
                                                                                  id={`checkbox-${foundPatientData._id}`}
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            {val?.notes ? (
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "5%",
                                                                                  height:
                                                                                    "30px",
                                                                                  textAlign:
                                                                                    "left",
                                                                                  lineHeight:
                                                                                    "35px",
                                                                                }}
                                                                              >
                                                                                <>
                                                                                  {
                                                                                    <OverlayTrigger
                                                                                      key={
                                                                                        "top"
                                                                                      }
                                                                                      placement={
                                                                                        "top"
                                                                                      }
                                                                                      overlay={
                                                                                        <Tooltip
                                                                                          id={`tooltip-${"top"}`}
                                                                                        >
                                                                                          <strong>
                                                                                            {
                                                                                              "Formula:"
                                                                                            }
                                                                                          </strong>
                                                                                          &nbsp;{" "}
                                                                                          {
                                                                                            val?.notes
                                                                                          }
                                                                                        </Tooltip>
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        src={
                                                                                          calcIcon
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                          width:
                                                                                            "36px",
                                                                                          height:
                                                                                            "auto",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      />
                                                                                    </OverlayTrigger>
                                                                                  }
                                                                                </>
                                                                              </td>
                                                                            ) : (
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "5%",
                                                                                }}
                                                                              ></td>
                                                                            )}
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "30%",
                                                                              }}
                                                                              className="pe-5"
                                                                            >
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    val?.field_name
                                                                                  }
                                                                                  readOnly
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "30%",
                                                                              }}
                                                                              className="pe-5"
                                                                            >
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    foundPatientData?.field_label
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    handlePatientLabel(
                                                                                      val?.field_name,
                                                                                      e
                                                                                        .target
                                                                                        .value
                                                                                    );
                                                                                  }}
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td
                                                                              className="vertical-aling-middle"
                                                                              style={{
                                                                                width:
                                                                                  "10%",
                                                                              }}
                                                                            >
                                                                              {!hideDisplayGraph.includes(
                                                                                val?.field_name
                                                                              ) && (
                                                                                <Switch
                                                                                  checkedChildren="Yes"
                                                                                  unCheckedChildren="No"
                                                                                  checked={handlePatientDisplayGraphSwitchcheck(
                                                                                    foundPatientData?.field_name,
                                                                                    "patient_data"
                                                                                  )}
                                                                                  className="blue-b"
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    handlePatientAddDataGraph(
                                                                                      val?.field_name,
                                                                                      e
                                                                                        ? "1"
                                                                                        : "0",
                                                                                      "patient_data"
                                                                                    );
                                                                                  }}
                                                                                />
                                                                              )}
                                                                            </td>
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "20%",
                                                                              }}
                                                                              className="vertical-aling-middle"
                                                                            >
                                                                              <Switch
                                                                                checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                checked={handlePatientSwitchcheck(
                                                                                  foundPatientData?.field_name
                                                                                )}
                                                                                className="blue-b"
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handlePatientSwitchOnChange(
                                                                                    val?.field_name,
                                                                                    e
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "0%",
                                                                              }}
                                                                            ></td>
                                                                          </tr>
                                                                        )}
                                                                      </Draggable>
                                                                    );
                                                                  }
                                                                )}{" "}
                                                                {
                                                                  provided.placeholder
                                                                }
                                                              </tbody>
                                                              <>
                                                                {!allZeroPatient &&
                                                                  hiddenPatientRows?.length >
                                                                    0 && (
                                                                    <tfoot>
                                                                      <tr>
                                                                        <td
                                                                          colSpan="8"
                                                                          className="text-center"
                                                                        >
                                                                          <button
                                                                            type="button"
                                                                            onClick={
                                                                              toggleShowMorePatient
                                                                            }
                                                                            className="btn btn-success"
                                                                          >
                                                                            {showAllPatient
                                                                              ? "Show Less"
                                                                              : "Show More"}
                                                                          </button>
                                                                        </td>
                                                                      </tr>
                                                                    </tfoot>
                                                                  )}
                                                              </>
                                                            </table>
                                                          )}
                                                        </Droppable>
                                                      </DragDropContext>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            Staffing
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              {/* New Design */}
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div
                                                    class="table-responsive"
                                                    ref={containerRef}
                                                    style={{
                                                      maxHeight: "800px",
                                                      overflowY: "auto",
                                                    }}
                                                  >
                                                    <DragDropContext
                                                      onDragEnd={
                                                        handleDragEndStaffingVisible
                                                      }
                                                    >
                                                      <Droppable droppableId="visible-data-table">
                                                        {(provided) => (
                                                          <table
                                                            className="table"
                                                            {...provided.droppableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            style={{
                                                              tableLayout:
                                                                "fixed",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <thead>
                                                              <tr>
                                                                <th
                                                                  style={{
                                                                    width: "5%",
                                                                  }}
                                                                ></th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "10%",
                                                                  }}
                                                                >
                                                                  Show/Hide
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "5%",
                                                                  }}
                                                                ></th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "30%",
                                                                  }}
                                                                >
                                                                  Key
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "30%",
                                                                  }}
                                                                >
                                                                  Label
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "10%",
                                                                  }}
                                                                >
                                                                  Display Graph
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "10%",
                                                                  }}
                                                                >
                                                                  Mandatory
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {visibleStaffingRows?.map(
                                                                (val, ind) => {
                                                                  const foundStaffingData =
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        val?.field_name
                                                                    );
                                                                  return (
                                                                    <Draggable
                                                                      key={
                                                                        val?._id
                                                                      }
                                                                      draggableId={
                                                                        val?._id
                                                                      }
                                                                      index={
                                                                        ind
                                                                      }
                                                                    >
                                                                      {(
                                                                        provided,
                                                                        snapshot
                                                                      ) => (
                                                                        <tr
                                                                          key={
                                                                            ind
                                                                          }
                                                                          ref={
                                                                            provided.innerRef
                                                                          }
                                                                          {...provided.draggableProps}
                                                                          className={`drag-row ${
                                                                            snapshot.isDragging
                                                                              ? "dragging"
                                                                              : ""
                                                                          }`}
                                                                          style={{
                                                                            ...provided
                                                                              .draggableProps
                                                                              .style,
                                                                            boxShadow:
                                                                              snapshot.isDragging
                                                                                ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                                                                                : "none",
                                                                            tableLayout:
                                                                              "fixed",
                                                                          }}
                                                                        >
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "5%",
                                                                            }}
                                                                          >
                                                                            <img
                                                                              src={
                                                                                themeMode.theme ===
                                                                                "DARK"
                                                                                  ? dragDark
                                                                                  : dragWhite
                                                                              }
                                                                              alt=""
                                                                              {...provided.dragHandleProps}
                                                                              style={{
                                                                                width:
                                                                                  "15px",
                                                                                height:
                                                                                  "auto",
                                                                                cursor:
                                                                                  "grab",
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "10%",
                                                                            }}
                                                                            className="sm-transparent-input"
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <div className="checkbox-item">
                                                                              <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                  foundStaffingData?.display >
                                                                                  0
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) =>
                                                                                  handleCheckboxStaffing(
                                                                                    foundStaffingData.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .checked
                                                                                  )
                                                                                }
                                                                                className="custom-checkbox"
                                                                                id={`checkbox-${foundStaffingData._id}`}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          {val?.notes ? (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "5%",
                                                                                height:
                                                                                  "30px",
                                                                                textAlign:
                                                                                  "left",
                                                                                lineHeight:
                                                                                  "35px",
                                                                              }}
                                                                            >
                                                                              <>
                                                                                {
                                                                                  <OverlayTrigger
                                                                                    key={
                                                                                      "top"
                                                                                    }
                                                                                    placement={
                                                                                      "top"
                                                                                    }
                                                                                    overlay={
                                                                                      <Tooltip
                                                                                        id={`tooltip-${"top"}`}
                                                                                      >
                                                                                        <strong>
                                                                                          {
                                                                                            "Formula:"
                                                                                          }
                                                                                        </strong>
                                                                                        &nbsp;{" "}
                                                                                        {
                                                                                          val?.notes
                                                                                        }
                                                                                      </Tooltip>
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        calcIcon
                                                                                      }
                                                                                      alt=""
                                                                                      style={{
                                                                                        width:
                                                                                          "36px",
                                                                                        height:
                                                                                          "auto",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    />
                                                                                  </OverlayTrigger>
                                                                                }
                                                                              </>
                                                                            </td>
                                                                          ) : (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "5%",
                                                                              }}
                                                                            ></td>
                                                                          )}
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30%",
                                                                            }}
                                                                            className="pe-5"
                                                                          >
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  val?.field_name
                                                                                }
                                                                                readOnly
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30%",
                                                                            }}
                                                                            className="pe-5"
                                                                          >
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  foundStaffingData?.field_label
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleStaffingLabel(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td
                                                                            className="vertical-aling-middle"
                                                                            style={{
                                                                              width:
                                                                                "10%",
                                                                            }}
                                                                          >
                                                                            {!disabledFields.includes(
                                                                              val?.field_name
                                                                            ) && (
                                                                              <Switch
                                                                                checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                checked={handlePatientDisplayGraphSwitchcheck(
                                                                                  foundStaffingData?.field_name,
                                                                                  "staffing"
                                                                                )}
                                                                                className="blue-b"
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handlePatientAddDataGraph(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      ? "1"
                                                                                      : "0",
                                                                                    "staffing"
                                                                                  );
                                                                                }}
                                                                              />
                                                                            )}
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "20%",
                                                                            }}
                                                                            className="vertical-aling-middle"
                                                                          >
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handleStaffingSwitchcheck(
                                                                                foundStaffingData?.field_name
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleStaffingSwitchOnChange(
                                                                                  val?.field_name,
                                                                                  e
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "0%",
                                                                            }}
                                                                          ></td>
                                                                        </tr>
                                                                      )}
                                                                    </Draggable>
                                                                  );
                                                                }
                                                              )}{" "}
                                                              {
                                                                provided.placeholder
                                                              }
                                                            </tbody>
                                                            <>
                                                              {!allZeroStaffing &&
                                                                hiddenStaffingRows?.length >
                                                                  0 && (
                                                                  <tfoot>
                                                                    <tr>
                                                                      <td
                                                                        colSpan="8"
                                                                        className="text-center"
                                                                      >
                                                                        <button
                                                                          type="button"
                                                                          onClick={
                                                                            toggleShowMoreStaffing
                                                                          }
                                                                          className="btn btn-success"
                                                                        >
                                                                          {showAllStaffing
                                                                            ? "Show Less"
                                                                            : "Show More"}
                                                                        </button>
                                                                      </td>
                                                                    </tr>
                                                                  </tfoot>
                                                                )}
                                                            </>
                                                          </table>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                          <Accordion.Header className="white-bg">
                                            Additional Data Tracking
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              {/*New Design */}
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div
                                                    class="table-responsive"
                                                    ref={containerRef}
                                                    style={{
                                                      maxHeight: "800px",
                                                      overflowY: "auto",
                                                    }}
                                                  >
                                                    <DragDropContext
                                                      onDragEnd={
                                                        handleDragEndAdditionalVisible
                                                      }
                                                    >
                                                      <Droppable droppableId="visible-data-table">
                                                        {(provided) => (
                                                          <table
                                                            className="table"
                                                            {...provided.droppableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            style={{
                                                              tableLayout:
                                                                "fixed",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <thead>
                                                              <tr>
                                                                <th
                                                                  style={{
                                                                    width: "5%",
                                                                  }}
                                                                ></th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "10%",
                                                                  }}
                                                                >
                                                                  Show/Hide
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "5%",
                                                                  }}
                                                                ></th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "30%",
                                                                  }}
                                                                >
                                                                  Key
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "30%",
                                                                  }}
                                                                >
                                                                  Label
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "10%",
                                                                  }}
                                                                >
                                                                  Display Graph
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width:
                                                                      "10%",
                                                                  }}
                                                                >
                                                                  Mandatory
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {visibleAdditionalRows?.map(
                                                                (val, ind) => {
                                                                  const foundAdditionalData =
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        val?.field_name
                                                                    );
                                                                  return (
                                                                    <Draggable
                                                                      key={
                                                                        val?._id
                                                                      }
                                                                      draggableId={
                                                                        val?._id
                                                                      }
                                                                      index={
                                                                        ind
                                                                      }
                                                                    >
                                                                      {(
                                                                        provided,
                                                                        snapshot
                                                                      ) => (
                                                                        <tr
                                                                          key={
                                                                            ind
                                                                          }
                                                                          ref={
                                                                            provided.innerRef
                                                                          }
                                                                          {...provided.draggableProps}
                                                                          className={`drag-row ${
                                                                            snapshot.isDragging
                                                                              ? "dragging"
                                                                              : ""
                                                                          }`}
                                                                          style={{
                                                                            ...provided
                                                                              .draggableProps
                                                                              .style,
                                                                            boxShadow:
                                                                              snapshot.isDragging
                                                                                ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                                                                                : "none",
                                                                            tableLayout:
                                                                              "fixed",
                                                                          }}
                                                                        >
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "5%",
                                                                            }}
                                                                          >
                                                                            <img
                                                                              src={
                                                                                themeMode.theme ===
                                                                                "DARK"
                                                                                  ? dragDark
                                                                                  : dragWhite
                                                                              }
                                                                              alt=""
                                                                              {...provided.dragHandleProps}
                                                                              style={{
                                                                                width:
                                                                                  "15px",
                                                                                height:
                                                                                  "auto",
                                                                                cursor:
                                                                                  "grab",
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "10%",
                                                                            }}
                                                                            className="sm-transparent-input"
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <div className="checkbox-item">
                                                                              <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                  foundAdditionalData?.display >
                                                                                  0
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) =>
                                                                                  handleCheckboxAdditional(
                                                                                    foundAdditionalData.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .checked
                                                                                  )
                                                                                }
                                                                                className="custom-checkbox"
                                                                                id={`checkbox-${foundAdditionalData._id}`}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          {val?.notes ? (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "5%",
                                                                                height:
                                                                                  "30px",
                                                                                textAlign:
                                                                                  "left",
                                                                                lineHeight:
                                                                                  "35px",
                                                                              }}
                                                                            >
                                                                              <>
                                                                                {
                                                                                  <OverlayTrigger
                                                                                    key={
                                                                                      "top"
                                                                                    }
                                                                                    placement={
                                                                                      "top"
                                                                                    }
                                                                                    overlay={
                                                                                      <Tooltip
                                                                                        id={`tooltip-${"top"}`}
                                                                                      >
                                                                                        <strong>
                                                                                          {
                                                                                            "Formula:"
                                                                                          }
                                                                                        </strong>
                                                                                        &nbsp;{" "}
                                                                                        {
                                                                                          val?.notes
                                                                                        }
                                                                                      </Tooltip>
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        calcIcon
                                                                                      }
                                                                                      alt=""
                                                                                      style={{
                                                                                        width:
                                                                                          "36px",
                                                                                        height:
                                                                                          "auto",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    />
                                                                                  </OverlayTrigger>
                                                                                }
                                                                              </>
                                                                            </td>
                                                                          ) : (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "5%",
                                                                              }}
                                                                            ></td>
                                                                          )}
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30%",
                                                                            }}
                                                                            className="pe-5"
                                                                          >
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  val?.field_name
                                                                                }
                                                                                readOnly
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30%",
                                                                            }}
                                                                            className="pe-5"
                                                                          >
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  foundAdditionalData?.field_label
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleAdditionalLabel(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td
                                                                            className="vertical-aling-middle"
                                                                            style={{
                                                                              width:
                                                                                "10%",
                                                                            }}
                                                                          >
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handlePatientDisplayGraphSwitchcheck(
                                                                                foundAdditionalData?.field_name,
                                                                                "additional_data_tracking"
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handlePatientAddDataGraph(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    ? "1"
                                                                                    : "0",
                                                                                  "additional_data_tracking"
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "20%",
                                                                            }}
                                                                            className="vertical-aling-middle"
                                                                          >
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handleAddDataSwitchcheck(
                                                                                foundAdditionalData?.field_name
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleAddDataSwitchOnChange(
                                                                                  val?.field_name,
                                                                                  e
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "0%",
                                                                            }}
                                                                          ></td>
                                                                        </tr>
                                                                      )}
                                                                    </Draggable>
                                                                  );
                                                                }
                                                              )}{" "}
                                                              {
                                                                provided.placeholder
                                                              }
                                                            </tbody>
                                                            <>
                                                              {!allZeroAdditional &&
                                                                hiddenAdditionalRows?.length >
                                                                  0 && (
                                                                  <tfoot>
                                                                    <tr>
                                                                      <td
                                                                        colSpan="8"
                                                                        className="text-center"
                                                                      >
                                                                        <button
                                                                          type="button"
                                                                          onClick={
                                                                            toggleShowMoreAdditional
                                                                          }
                                                                          className="btn btn-success"
                                                                        >
                                                                          {showAllAdditional
                                                                            ? "Show Less"
                                                                            : "Show More"}
                                                                        </button>
                                                                      </td>
                                                                    </tr>
                                                                  </tfoot>
                                                                )}
                                                            </>
                                                          </table>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                          <Accordion.Header>
                                            Realtime Formula
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-md-12 editor-styles">
                                                    {Object.keys(
                                                      realtimeFormulaHeaders
                                                    )?.map((e) => (
                                                      <div
                                                        key={e}
                                                        style={{
                                                          lineHeight: "40px",
                                                          fontSize: "1rem",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            fontSize: "1.1rem",
                                                          }}
                                                        >
                                                          {e}&nbsp;:&nbsp;
                                                        </span>

                                                        {
                                                          realtimeFormulaHeaders[
                                                            e
                                                          ]
                                                        }
                                                      </div>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
      <TargetHistoryModal
        show={showModal.state}
        id={showModal.id}
        costCenterNumber={initialValues?.cost_center_number}
        onHide={() => {
          setShowModal({ state: false, id: null });
        }}
      />
    </>
  );
}

export default EditNewCostCenter;
